import React from 'react';
import SEO from '../components/seo';
import withI18next from '../components/withI18Next';

interface Props {
  pageContext: {
    locale: string;
  };
}

const IndexPage = ({ pageContext: { locale } }: Props) => {
  return (
    <div className="container">
      <SEO pageImage="/" pageUrl="https://tuovu.fi" lang={locale} />
      <h1>Tuovu</h1>
      <h2>coding...</h2>
    </div>
  );
};

export default withI18next({ ns: 'common' })(IndexPage);
